* {margin: 0; padding: 0;}

body, html {
  width: 100%;
  height: 100%;
}

#root {
  width: 100%;
  height: 100%;
}